import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import { call, put, takeEvery, fork } from 'redux-saga/effects';
import { GET_IES_DASHBOARD } from './types';
import Config from '../../config';
import { buildUrl, isDebug } from '../../helpers/Utils';
import { getIESDashboardSuccess, getIESDashboardError } from './actions';

const getDashboardReportOptionsRequest = async () => {
  return axios.get(`${Config.apiServiceHost}/api/Dashboard/dashboardReport`, {
    headers: { ...(await authHeader()).headers },
  });
};

const getReferralInChartDataRequest = async query => {
  let url = `${Config.apiServiceHost}/api/Dashboard/referralInChartData`;
  return axios.post(url, query, await authHeader());
};

const getClientCompletedSurveyChartDataRequest = async query => {
  return axios.post(
    `${Config.apiServiceHost}/api/Dashboard/clientCompletedSurveyChartData`,
    query,
    {
      headers: { ...(await authHeader()).headers },
    }
  );
};

const getClientSatisfactionBySpecializePopulationChartDataRequest = async query => {
  return axios.post(
    `${Config.apiServiceHost}/api/Dashboard/clientSatisfactionBySpecializePopulationChartData`,
    query,
    {
      headers: { ...(await authHeader()).headers },
    }
  );
};

const getHiringPerSectorChartDataRequest = async query => {
  const url = `${Config.apiServiceHost}/api/Dashboard/hiringPerSectorChartData`;
  return axios.post(url, query, await authHeader());
};

const getPlannedItemBudgetTrackingDataRequest = async query => {
  const url = `${Config.apiServiceHost}/api/Dashboard/plannedItemBudgetTrackingData`;
  return axios.post(url, query, await authHeader());
};

const getUserCompletedTrainingDataRequest = async query => {
  return axios.get(`${Config.apiServerHost}/api/Dashboard/userCompletedTrainingData`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const getInsightChartDataRequest = async query => {
  return axios.get(`${Config.apiServerHost}/api/Dashboard/insightChartData`, {
    params: query,
    headers: { ...(await authHeader()).headers },
  });
};

const getCaseActivityStateDataDataRequest = async query => {
  return axios.post(`${Config.apiServerHost}/api/Dashboard/caseActivityStateData`, query, {
    headers: { ...(await authHeader()).headers },
  });
};

const updateDashboardReportOptionsRequest = async data => {
  return axios.post(`${Config.apiServerHost}/api/Dashboard/dashboardReport`, data, {
    headers: { ...(await authHeader()).headers },
  });
};

let currentCancelToken;

const getIESDashboardRequest = async payload => {
  // If there's an ongoing request, cancel it
  // if (currentCancelToken) {
  //   console.log('Operation canceled due to new request.');
  //   currentCancelToken.cancel('Operation canceled due to new request.');
  // }

  // Create a new CancelToken source
  // currentCancelToken = axios.CancelToken.source();

  //Grab local storage debug flag
  const debug = isDebug();

  const url = debug
    ? `${Config.apiServiceHostForExport}/api/Dashboard/iesDashBoard-single?debug=true`
    : `${Config.apiServiceHostForExport}/api/Dashboard/iesDashBoard-single`;

  return axios.post(url, payload, {
    // cancelToken: currentCancelToken.token,
    headers: { ...(await authHeader()).headers },
  });
};

const getJobSeekerProfileRequest = async clientId => {
  return axios.get(`${Config.apiServerHost}/api/Dashboard/jobSeekerProfile/${clientId}`, {
    headers: { ...(await authHeader()).headers },
  });
};

const dashboardTypes = [
  'IESDashboardModel_Insights',
  'IESDashboardModel_ActiveClientsByStreamChartData',
  'IESDashboardModel_BudgetTrackingChartData',
  'IESDashboardModel_JobAttainmentOutcomesChartData',
  'IESDashboardModel_ActiveClientsPerSpecializedPopulationsChartData',
  'IESDashboardModel_EmployedAtOutcomesThatReceiveServiceInterventionsChartData',
  'IESDashboardModel_JobAttainmentOutcomeBySpecializedPopulationChartData',
  'IESDashboardModel_SkillsDevelopmentChartData',
  'IESDashboardModel_PlannedItemChartData',
  'IESDashboardModel_EmploymentAssistanceServicesChartData',
  'IESDashboardModel_TargetSchemeModel',
  'IESDashboardModel_IncomeSource',
  'IESDashboardModel_JobSeekerRetainedEmployment',
  'IESDashboardModel_ReferralInChartModal',
  'IESDashboardModel_AverageTimeByStream',
  'IESDashboardModel_CompletedTrainingModel',
  'IESDashboardModel_SpecializedClientsPerSite',
  'IESDashboardModel_ClientCompletedSurveyChartData',
  'IESDashboardModel_StackedDataByStreamChartData',
  'IESDashboardModel_ServedSpecializedPopulationAtOutcomeChartData',
];

function* fetchDashboardType(payload, type) {
  try {
    const response = yield call(getIESDashboardRequest, { ...payload, dashboardType: type });
    yield put(getIESDashboardSuccess(response.data));
  } catch (error) {
    console.error(error);
    const errorMessage = error.response?.data?.message ?? 'Something went wrong.';
    yield put(getIESDashboardError(errorMessage));
  }
}

function* getIESDashboard({ payload }) {
  try {
    yield put(getIESDashboardSuccess(null));
    for (const type of dashboardTypes) {
      yield fork(fetchDashboardType, payload, type);
    }
  } catch (error) {
    console.error('Unexpected error in getIESDashboard saga:', error);
    yield put(getIESDashboardError('Unexpected error occurred.'));
  }
}

export default function* rootSaga() {
  yield takeEvery(GET_IES_DASHBOARD, getIESDashboard);
}

export {
  getDashboardReportOptionsRequest,
  updateDashboardReportOptionsRequest,
  getReferralInChartDataRequest,
  getInsightChartDataRequest,
  getCaseActivityStateDataDataRequest,
  getUserCompletedTrainingDataRequest,
  getHiringPerSectorChartDataRequest,
  getClientSatisfactionBySpecializePopulationChartDataRequest,
  getClientCompletedSurveyChartDataRequest,
  getPlannedItemBudgetTrackingDataRequest,
  getJobSeekerProfileRequest,
};
