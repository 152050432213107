/* eslint-disable react/no-unused-state */
import { logger } from '../logger';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row, Card, CardTitle, Button } from 'reactstrap';
import { Colxx } from '../components/common/CustomBootstrap';
import { getCompanyClass } from '../helpers/Utils';
import { loggingService } from '../services/logging-service';
import { serializeError } from 'serialize-error';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]).isRequired,
  fallback: PropTypes.node,
  location: PropTypes.string.isRequired,
};

const defaultProps = {
  fallback: null,
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null,
      hasUpgradeError: false,
      secondCount: 5,
    };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info)

    if (error) {
      const err = serializeError(error);
      loggingService.error(err ? err.stack : error.message);

      if (error.toString().includes('ChunkLoadError')) {
        this.setState({ hasUpgradeError: true });
        const interval = setInterval(() => {
          this.setState({ secondCount: this.state.secondCount - 1 });
        }, 1000);
        return () => clearInterval(interval);
      }
    }

    logger.error({ error, info });

    this.setState({
      error,
      info,
    });

    //TODO: WE SHOULD LOG THIS SOMEWHERE ON THE SERVICE
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  getWrapper = () => {
    return React.Fragment;
  };

  handleReload = () => {
    window.location.reload();
  };

  render() {
    const { hasUpgradeError, secondCount } = this.state;
    const { fallback } = this.props;

    if (secondCount === 0) {
      window.location.reload();
    }

    if (this.state.hasError) {
      // You can render any custom fallback UI
      //const Wrapper = this.getWrapper();

      if (fallback) {
        const Component = fallback;
        return <Component />;
      }

      return (
        <Fragment>
          <div className={`fixed-background ${getCompanyClass()}`} />
          <main>
            <div className="container">
              <Row className="h-100">
                <Colxx xxs="12" md="10" className="mx-auto my-auto">
                  <Card className="auth-card">
                    <div className={`position-relative image-side ${getCompanyClass()}`}>
                      <p className="text-white h2">ESCASES</p>
                      <p className="white mb-0">All-in-one case management resource solution</p>
                    </div>
                    <div className="form-side">
                      <span className={`logo-single ${getCompanyClass()}`} />
                      {hasUpgradeError ? (
                        <>
                          <CardTitle className="mb-4">
                            The page you're trying to access has upgraded. You'll be redirected to
                            the correct page in {secondCount} seconds. If you’re not redirected,
                            please manually refresh the page.
                          </CardTitle>
                        </>
                      ) : (
                        <>
                          <CardTitle className="mb-4">{`To ensure the best experience with our latest update, please refresh your browser.`}</CardTitle>
                          <p className="mb-0 text-muted text-small mb-0">
                            {'Quick Fix: Just Refresh Your Screen!'}
                          </p>
                          <ul>
                            <li>{'Desktop: Press Ctrl + R (Windows) or Cmd + R (Mac).'}</li>
                            <li>
                              {
                                'Mobile: Close and reopen your browser or pull down to refresh. Still stuck? Our support team is ready to help. Refresh now and get right back to it!'
                              }
                            </li>
                          </ul>
                          <p className="display-1 font-weight-bold mb-5">404</p>
                        </>
                      )}
                    </div>
                  </Card>
                </Colxx>
              </Row>
            </div>
          </main>
        </Fragment>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = propTypes;
ErrorBoundary.defaultProps = defaultProps;

export default ErrorBoundary;
