import axios from 'axios';
import { authHeader } from '../../helpers/auth-header';
import Config from '../../config';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  OUTCOME_CHECKPOINT_GET_BY_QUERY,
  OUTCOME_CHECKPOINT_UPDATE,
  OUTCOME_CHECKPOINT_SYNC,
  GET_REFRESH_OUTCOME_CHECKPOINT_GET_BY_QUERY,
  OUTCOME_CHECKPOINT_DETECT_DIFFERENCE,
  OUTCOME_CHECKPOINT_SINGLE_UPDATE,
  GET_REFRESH_OUTCOME_CHECKPOINT_ATTACHMENT_GET_BY_QUERY,
} from './types';

import {
  getOutcomeCheckpointByQueryError,
  getOutcomeCheckpointByQuerySuccess,
  updateOutcomeCheckpointError,
  syncOutcomeCheckpointError,
  getRefreshOutcomeCheckpointByQuerySuccess,
  syncOutcomeCheckpointSuccess,
  detectDifferenceOutcomeCheckpointSuccess,
  getRefreshOutcomeCheckpointAttachmentsByQuerySuccess,
} from './actions';
import { getClientServicePlanRequest } from '../client/saga';
import { getClientServicePlanSuccess } from '../actions';

const getLiveCheckpointTransactions = async () => {
  return axios.get(
    `${Config.apiServerHost}/api/OutcomeCheckpoint/transaction/live`,
    await authHeader()
  );
};

const getOutcomeCheckpointByIdRequest = async id => {
  return axios.get(`${Config.apiServerHost}/api/OutcomeCheckpoint/${id}`, {
    headers: { ...(await authHeader()).headers },
  });
};

const updateSingleOutcomeCheckpointRequest = async payload => {
  return axios.post(`${Config.apiServerHost}/api/OutcomeCheckpoint/updateSingle`, payload, {
    headers: { ...(await authHeader()).headers },
  });
};

const getOutcomeCheckpointByQueryRequest = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/OutcomeCheckpoint`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

const getOutcomeCheckpointSurveyStatisticRequest = async queryParam => {
  return axios.post(
    `${Config.apiServerHost}/api/OutcomeCheckpointSurvey/getOutcomeCheckpointSurveyStatistic`,
    queryParam,
    {
      headers: { ...(await authHeader()).headers },
    }
  );
};
function* getOutcomeCheckpointByQuery({ payload }) {
  try {
    let response = yield call(getOutcomeCheckpointByQueryRequest, payload);
    yield put(getOutcomeCheckpointByQuerySuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Get Checkpoint',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(getOutcomeCheckpointByQueryError(message));
  }
}

function* getRefreshOutcomeCheckpointByQuery({ payload }) {
  try {
    let response = yield call(getOutcomeCheckpointByQueryRequest, payload);
    yield put(getRefreshOutcomeCheckpointByQuerySuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Get Checkpoint',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(getOutcomeCheckpointByQueryError(message));
  }
}

function* getRefreshOutcomeCheckpointAttachmentsByQuery({ payload }) {
  try {
    let response = yield call(getOutcomeCheckpointByQueryRequest, payload);
    yield put(getRefreshOutcomeCheckpointAttachmentsByQuerySuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Get Checkpoint',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(getOutcomeCheckpointByQueryError(message));
  }
}

const deleteAttachmentRequest = async attachments => {
  return axios.post(
    `${Config.apiServerHost}/api/OutcomeCheckpoint/deleteAttachment`,
    attachments,
    await authHeader()
  );
};

const saveOutcomeCheckpointAttachmentRequest = async (attachments, documentType, description) => {
  return axios.post(
    `${Config.apiServerHost}/api/OutcomeCheckpoint/saveAttachment?documentType=${documentType}&description=${description}`,
    attachments,
    await authHeader()
  );
};

const saveOutcomeCheckpointAttachmentExistingRequest = async (
  attachments,
  documentType,
  description
) => {
  return axios.post(
    `${Config.apiServerHost}/api/OutcomeCheckpoint/saveAttachmentExisting?documentType=${documentType}&description=${description}`,
    attachments,
    await authHeader()
  );
};

const detectDifferenceOutcomeCheckpointRequest = async queryParam => {
  return axios.get(`${Config.apiServerHost}/api/OutcomeCheckpoint/detectDifference`, {
    params: queryParam,
    headers: { ...(await authHeader()).headers },
  });
};

const updateOutcomeCheckpointRequest = async outcomeQuestionnaire => {
  return axios.post(
    `${Config.apiServerHost}/api/OutcomeCheckpoint`,
    outcomeQuestionnaire,
    await authHeader()
  );
};

const updateOutcomeCheckpointNotifyRequest = async (id, value) => {
  return axios.post(
    `${Config.apiServerHost}/api/OutcomeCheckpoint/updateNotify/${id}/${value}`,
    {},
    await authHeader()
  );
};

const syncOutcomeCheckpointRequest = async data => {
  return axios.post(
    `${Config.apiServerHost}/api/OutcomeCheckpoint/sync?clientId=${
      data.clientId
    }&servicePlanHeaderId=${data.servicePlanHeaderId}&clientReferenceNumberOverride=${
      data.clientReferenceNumberOverride
    }&outcomeCkpReferenceNumber=${data.outcomeCkpReferenceNumber || ''}`,
    '{}',
    await authHeader()
  );
};

const validateUpdateOutcomeCheckpointRequest = async data => {
  return axios.post(
    `${Config.apiServerHost}/api/OutcomeCheckpoint/validate`,
    data,
    await authHeader()
  );
};

function* detectDifferenceOutcomeCheckpoint({ payload }) {
  try {
    let response = yield call(detectDifferenceOutcomeCheckpointRequest, payload);
    yield put(detectDifferenceOutcomeCheckpointSuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Update Checkpoint',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(updateOutcomeCheckpointError(message));
  }
}

function* updateOutcomeCheckpoint({ payload }) {
  try {
    const { clientId, servicePlanHeaderId, items } = payload;
    let response = yield call(updateOutcomeCheckpointRequest, items);
    response = yield call(getOutcomeCheckpointByQueryRequest, { clientId, servicePlanHeaderId });
    yield put(getOutcomeCheckpointByQuerySuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Update Checkpoint',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(updateOutcomeCheckpointError(message));
  }
}

function* updateSingleOutcomeCheckpoint({ payload }) {
  try {
    const { clientId, servicePlanHeaderId, item } = payload;
    let response = yield call(updateSingleOutcomeCheckpointRequest, item);
    response = yield call(getOutcomeCheckpointByQueryRequest, { clientId, servicePlanHeaderId });
    yield put(getOutcomeCheckpointByQuerySuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Update Checkpoint',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(updateOutcomeCheckpointError(message));
  }
}

function* syncOutcomeCheckpoint({ payload }) {
  try {
    const {
      clientId,
      servicePlanHeaderId,
      clientReferenceNumberOverride,
      outcomeCkpReferenceNumber,
    } = payload;
    let response = yield call(syncOutcomeCheckpointRequest, {
      clientId,
      servicePlanHeaderId,
      clientReferenceNumberOverride,
      outcomeCkpReferenceNumber,
    });
    response = yield call(getOutcomeCheckpointByQueryRequest, { clientId, servicePlanHeaderId });
    yield put(getOutcomeCheckpointByQuerySuccess(response.data));
    yield put(syncOutcomeCheckpointSuccess(response.data));
    response = yield call(getClientServicePlanRequest, { clientId, id: servicePlanHeaderId });
    yield put(getClientServicePlanSuccess(response.data));
  } catch (error) {
    const message = {
      title: 'Sync Checkpoint',
      text: error.response?.data?.message ?? 'something went wrong.',
    };
    yield put(syncOutcomeCheckpointError(message));
  }
}

export default function* rootSaga() {
  yield takeEvery(OUTCOME_CHECKPOINT_GET_BY_QUERY, getOutcomeCheckpointByQuery);
  yield takeEvery(GET_REFRESH_OUTCOME_CHECKPOINT_GET_BY_QUERY, getRefreshOutcomeCheckpointByQuery);
  yield takeEvery(
    GET_REFRESH_OUTCOME_CHECKPOINT_ATTACHMENT_GET_BY_QUERY,
    getRefreshOutcomeCheckpointAttachmentsByQuery
  );
  yield takeEvery(OUTCOME_CHECKPOINT_UPDATE, updateOutcomeCheckpoint);
  yield takeEvery(OUTCOME_CHECKPOINT_SINGLE_UPDATE, updateSingleOutcomeCheckpoint);
  yield takeEvery(OUTCOME_CHECKPOINT_SYNC, syncOutcomeCheckpoint);
  yield takeEvery(OUTCOME_CHECKPOINT_DETECT_DIFFERENCE, detectDifferenceOutcomeCheckpoint);
}

export {
  getOutcomeCheckpointByQueryRequest,
  saveOutcomeCheckpointAttachmentRequest,
  saveOutcomeCheckpointAttachmentExistingRequest,
  getLiveCheckpointTransactions,
  getOutcomeCheckpointByIdRequest,
  updateSingleOutcomeCheckpointRequest,
  detectDifferenceOutcomeCheckpointRequest,
  deleteAttachmentRequest,
  updateOutcomeCheckpointNotifyRequest,
  validateUpdateOutcomeCheckpointRequest,
  getOutcomeCheckpointSurveyStatisticRequest,
};
